<template>
  <v-container fluid>
    <v-row
      align="center"
      justify="center"
      class="text-center"
      :style="style_ldaperror"
    >
      <v-icon :dark="this.$store.state.darkTheme ? true:false">
        fas fa-bug
      </v-icon>
            &emsp;
      <h1> Ldap error </h1>
            &emsp;
      <v-icon :dark="this.$store.state.darkTheme ? true:false">
        fas fa-bug
      </v-icon>
    </v-row>

    <v-row
      align="center"
      justify="center"
    >
      <video
        playsinline
        autoplay
        muted
        loop
        :height="this.$store.state.windowH/2.5"
      >
        <source
          :src="require('@/assets/error_video.mp4')"
          type="video/mp4"
        >
      </video>
    </v-row>

    <v-row
      align="center"
      justify="center"
      class="text-center"
      :style="style_ldaperror"
    >
      Please contact PERSEUS admins <br>
    </v-row>
    <v-row
      align="center"
      justify="center"
      class="text-center"
      :style="style_ldaperror"
    >
      <v-btn
        href="mailto:sos-gricad@univ-grenoble-alpes.fr"
        color="primary"
        :style="style_btn"
        x-large
        class="mx-6 my-4"
      >
        By sending an e-mail to <br>
        sos-gricad@univ-grenoble-alpes.fr
      </v-btn>
      or
      <v-btn
        href="sos-gricad.univ-grenoble-alpes.fr"
        color="primary"
        :style="style_btn"
        x-large
        class="mx-6 my-6"
      >
        By opening a ticket on <br>
        sos-gricad.univ-grenoble-alpes.fr
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
  import config from '@/config'

  export default {
    data: () => ({
      // Couleur
      red: config.colors.gricad_red_dark1,
      card_bg_dark: config.colors.card_bg_dark,
      card_bg_light: config.colors.card_bg_light,
      barchart_color: config.colors.gricad_blue_dark3,
      blue: config.colors.gricad_oceanblue,
      bg_dark: config.colors.gricad_darkblue_dark2,
    }),

    computed: {
      style_centerblock () {
        return {
          height: `${this.$store.state.windowH - 200}px`,
          'background-color': 'transparent',
          'font-size': '30px',
        }
      },
      style_ldaperror () {
        return {
          // 'color': this.red,
          'background-color': 'transparent',
          'font-size': '30px',
          'margin-top': '30px',
          'margin-bottom': '30px',
        }
      },
      style_btn () {
        return {
          // 'height': `${this.$store.state.windowH/4}px`,
          // 'width': `${this.$store.state.windowW/4}px`,
          'background-color': this.blue,
          color: 'black',
          'font-size': '20px',
          'text-transform': 'none',
          height: '100px',
        }
      },
      style_bigbtn () {
        return {
          height: `${this.$store.state.windowH / 4}px`,
          width: `${this.$store.state.windowW / 4}px`,
          'background-color': this.blue,

        }
      },
    },

  }
</script>

<style lang="scss">
</style>
